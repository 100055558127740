import axios from 'axios'
import { notification } from 'ant-design-vue';

// dev 
// export const baseUrl = 'http://localhost:6500/tool/api'
// export const storageLabel = 'cl-train-dev-token'

// //prod
// export const baseUrl = 'https://sxhy2024.hainnu.edu.cn/toolApi'
// export const storageLabel = 'cl-train-admin-token'

//coin
export const baseUrl = 'http://52.199.17.16/coinApi'
export const storageLabel = 'cl-coin-admin-token'

// 创建axios的对象
const instance = axios.create({
    baseURL: baseUrl,  //配置固定域名
    timeout: 30000
})

// 请求拦截
// 所有的网络请求都会走这个方法,可以在请求添加自定义内容
instance.interceptors.request.use(
    function (config) {
        config.headers.Authorization = localStorage.getItem(storageLabel) // 请求头添加token值
        return config
    },
    function (err) {
        return Promise.request(err)
    }
)

// 响应拦截
// 此处可以根据服务器返回的状态码做相应的数据
instance.interceptors.response.use(
    function (response) {
        const res = response
        // console.log('axios-res', res.config.url, res.data)
        if (res.data.status === 500) {
            notification["error"]({ message: "提醒", description: res.data.message });
        } else if (res.data.status === 403) {
            localStorage.removeItem(storageLabel);
            location.href = './#/login'
        }
        return res.data
    },
    function (err) {
        console.log(err)
        return { success: false }
    }
)

// 封装get和post请求
export function get(url, params) {
    return instance.get(url, { params })
}

export function post(url, data) {
    return instance.post(url, data)
}

export default instance;