<template>
  <a-button :type="customType" :class="customClass" :size="customSize" :disabled="disabled">
    <slot />
  </a-button>
</template>
<script setup>
import { watch, ref, defineProps, onMounted } from "vue";
let props = defineProps({
  type: { type: String, default: () => { } },
  size: { type: String, default: () => "" },
  disabled: { type: Boolean, default: () => false },
});
const customType = ref(props.type);
const customSize = ref(props.size);
const disabled = ref(props.disabled);
const customClass = ref("");

// Watch type prop
watch(
  () => props.type,
  (newValue) => {
    initTheme(newValue);
  }
);

// Watch size prop
watch(
  () => props.size,
  (newValue) => {
    customSize.value = newValue;
  }
);

// Watch disabled prop
watch(
  () => props.disabled,
  (newValue) => {
    disabled.value = newValue;
  }
);

onMounted(() => {
  initTheme(props.type);
});

function initTheme(type) {
  switch (type) {
    case "primary":
      customType.value = "primary";
      break;
    case "red":
      customClass.value = 'c-button-red'
      break;
    case "green":
      customClass.value = 'c-button-green'
      break;
    default:
      customType.value = type;
  }
}
</script>
<style lang="scss" scoped>
.c-button-red {
  color: #fff;
  background-color: #C04945;
  border-color: #C04945;
}

.c-button-red:hover {
  color: #fff;
  background-color: #7E2522;
  border-color: #7E2522;
}

.c-button-red[disabled], .c-button-red[disabled]:hover, .c-button-red[disabled]:focus, .c-button-red[disabled]:active {
  color: #C04945;
  background: #c0494528;
  border-color: #c0494528;
  text-shadow: none;
  box-shadow: none;
}
.c-button-green {
  color: #fff;
  background-color: #449E53;
  border-color: #449E53;
}

.c-button-green:hover {
  color: #fff;
  background-color: #225B2B;
  border-color: #225B2B;
}

.c-button-green[disabled], .c-button-green[disabled]:hover, .c-button-green[disabled]:focus, .c-button-green[disabled]:active {
  color: #449E53;
  background: #449E5328;
  border-color: #449E5328;
  text-shadow: none;
  box-shadow: none;
}
</style>