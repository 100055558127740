import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/reset.css';
import { post, get, baseUrl, storageLabel } from '@/utils/axios.js'
import { notification } from 'ant-design-vue';
import { message } from 'ant-design-vue';
import '@/style/variable.css'
import CButton from '@/views/components/c-button.vue'
import i18n from './utils/i18n.js';

const app = createApp(App);
app.use(router);
app.use(Antd)
app.config.globalProperties.baseUrl = baseUrl
app.config.globalProperties.post = post
app.config.globalProperties.get = get
app.config.globalProperties.storageLabel = storageLabel
app.config.globalProperties.notification = notification
app.config.globalProperties.message = message
app.config.globalProperties.phoneFlag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);

// app.config.globalProperties.companyConfig = {
//   code: 'zp',
//   productName: "智派买卖点训练软件",
//   productAbbreviation: "智派买卖点",
//   icon: 'https://www.smartq.info/tool/favicon-zp.ico',
//   icp: '滇ICP备2024040789号',
//   subject: 'stock,future'
// }

// app.config.globalProperties.companyConfig = {
//   code: 'jz',
//   productName: "九章缠论",
//   productAbbreviation: "九章缠论",
//   icon: 'https://jzlc.work/tool/favicon-jz.ico',
//   icp: '滇ICP备2024022252号-2',
//   subject: 'stock,future'
// }

//期货客户
// app.config.globalProperties.companyConfig = {
//   code: 'paisen',
//   productName: "看盘系统",
//   productAbbreviation: "看盘系统",
//   icon: 'https://aiquantcloud.com/tool/favicon-paisen.ico',
//   icp: '浙ICP备2023006359号-1',
//   subject: 'stock,future'
// }
// app.config.globalProperties.companyConfig = {
//   code: 'zjdx',
//   productName: "浙大投资协会缠论训练软件",
//   productAbbreviation: "浙大投资协会缠论训练",
//   icon: 'https://aiquantcloud.com/tool/favicon-zjdx.ico',
//   icp: '浙ICP备2023006359号-1',
//   subject: 'stock,future'
// }
// app.config.globalProperties.companyConfig = {
//   code:'cy',
//   productName: "财云交易助手",
//   productAbbreviation: "财云助手",
//   icon: 'https://zhushou.caiair.com/zhushou/favicon-cy.ico',
//   icp: ''
// }
// app.config.globalProperties.companyConfig = {
//   code: process.env.VUE_APP_COMPANY_CODE,
//   productName: process.env.VUE_APP_PRODUCT_NAME,
//   productAbbreviation: process.env.VUE_APP_PRODUCT_ABBREVIATION || '', // 可选字段
//   icon: process.env.VUE_APP_ICON,
//   icp: process.env.VUE_APP_ICP,
// };

app.config.globalProperties.companyConfig = {
  code: 'coin',
  productName: "Yioai Strategy",
  productAbbreviation: "Yioai Strategy",
  icon: 'https://aiquantcloud.com/tool/favicon-paisen.ico',
  icp: '',
  subject: 'coin'
}
app.component('c-button', CButton);
app.use(i18n);
app.mount('#app');