import { createI18n } from 'vue-i18n';

const messages = {
  en: {
    //公共
    common: {
      yes: 'yes',
      no: 'no',
      pleaseInput: 'please input',
      pleaseSelect: 'please select',
      search: 'search',
      check: 'check',
      delete: 'delete',
      confirm: 'confirm',
      cancel: 'cancel',
    },
    //金融
    finance: {
      yield: 'yield',
      strategy: 'strategy',
      index: 'index',
      cumulativeRate: 'cumulative rate of return',
      symbol: 'symbol',
      stock: 'stock',
      future: 'future',
      coin: 'coin'
    },
    //登录
    login: {
      welcomne: 'WELCOME LOGIN',
      email: 'EMAIL',
      emailPlaceholder: 'Please enter your email',
      password: 'PASSWORD',
      passwordPlaceholder: 'Please enter your password',
      loginBtn: 'LOGIN',
      emailRegisterBtn: 'EMAIL REGISTER',
      emailCodeLoginBtn: 'EMAIL CODE LOGIN',
    },
    menu: {
      simulatedTrading: 'Simulated Trading',
      strategySquare: 'Strategy Square',
      backTrade: 'Back Trade',
      trainingReview: 'Training Review',
      realStrategy: 'Real Strategy',
      tradeTool: 'Trade Tool',
      autoTrade:'auto trade',
      userCenter: 'User Center',
      digitalMarket: 'Digital Market',
      tradingPlanet: 'Trading Planet',
      more: 'more',
      change: 'change',
      train:'Simulated Trading'
    },
    //策略中心页面
    strategy: {
      gridStrategy: "Grid Strategy",
      trendStrategy: "Trend Strategy",
      arbitrageStrategy: "Arbitrage Strategy",
      hedgingStrategy: "Hedging Strategy",
      meanReversionStrategy: "Mean Reversion Strategy",
    },
    //缠论
    cl: {
      contract: 'contract',
      initialCapital: 'initial capital',
      trainingStartDate: 'training start date',
      startTrain: 'start train',
      endTrain: 'end train',
      customizedAssisted: 'Customized assisted',
      lineDay: 'day',
      lineHour: 'hour',
      line30: '30min',
      line15: '15min',
      line5: '5min',
      lineMulti: 'multi-line',
      tip: 'This Chaos Theory tool is only used for technical analysis simulation training. All training results are not investment advice. Investment is risky and you need to be cautious when entering the market! If you need real-time trading software, please contact customer service to open an account with a regular brokerage firm.'
    },
    //回测
    back: {
      gridStrategy: "Grid Strategy",
      trendStrategy: "Trend Strategy",
      arbitrageStrategy: "Arbitrage Strategy",
      hedgingStrategy: "Hedging Strategy",
      meanReversionStrategy: "Mean Reversion Strategy",
      grid: {
        parameterSettings: 'parameter settings',
        loopOpen: 'enable loop opening',
        openBlateralPositions: 'open bilateral positions',
        firstOrderDirection: 'first order direction',
        long: 'long',
        short: 'short',
        longSet: 'long position opening settings',
        shortSet: 'short position opening settings',
        longOpen: 'number of lots for the first opening',
        longRatio: 'first order take profit',
        addWay: 'price Addition Method',
        shortOpen: 'number of lots for the first opening',
        shortRatio: 'first order take profit',
        addGrid: 'add grid',
        deleteGrid: 'reduce grid',
        grid: 'grid',
        number: 'number',
        price: 'price',
        profit: 'profit',
        backtestAnalysis: 'backtest analysis'
      },
      //网格策略回测结果
      backModal: {
        title: 'grid strategy backtesting analysis',
        backtestingIndicators: 'backtesting indicators',
        backtestCurve: 'backtest curve',
        param: 'analyze param'
      }
    },
    //训练记录
    history: {
      symbol: 'symbol',
      tradeSubject: 'type',
      startDate: 'start date',
      endDate: 'end date',
      initFund: 'init fund',
      endFund: 'end fund',
      profit: 'profit',
      profitRate: 'profit rate',
      createTime: 'create time',
      detail: 'detail'
    },
    //实盘
    trade: {
      contractSelection: 'selection',
      marketUpdateTime: 'market update time',
      dayLine: 'DayLine'
    },
    //缠论星球
    earth: {
      varietyNumer: 'number',
      firstBuy: 'first buy',
      secondBuy: 'second buy',
      thirdBuy: 'third buy',
      firstSell: 'first sell',
      secondSell: 'second sell',
      thirdSell: 'third sell',
      plate: 'plate'
    },
    //个人中心
    user: {
      logOut: 'Log out',
      logOutTip: 'Are you sure you want to log out ?'
    }
  },
  zh: {
    //公共
    common: {
      yes: '是',
      no: '否',
      pleaseInput: '请输入',
      pleaseSelect: '请选择',
      search: '查询',
      check: '查看',
      delete: '删除',
      confirm: '确认',
      cancel: '取消',
    },
    //金融
    finance: {
      yield: '收益率',
      strategy: '策略',
      index: '指数',
      cumulativeRate: '累计收益率',
      symbol: '代码',
      stock: '股票',
      future: '期货',
      coin: '数币'
    },
    login: {
      welcomne: '欢迎登录',
      email: '账号',
      password: '密码',
      emailPlaceholder: '请输入账户',
      passwordPlaceholder: '请输入密码',
      loginBtn: '登录',
      emailRegisterBtn: '邮箱注册',
      emailCodeLoginBtn: '邮箱验证码登录',
    },
    menu: {
      strategySquare: '策略广场',
      backTrade: '策略回测',
      simulatedTrading: '交易训练',
      trainingReview: '训练复盘',
      realStrategy: '实盘策略',
      tradeTool: '交易工具',
      autoTrade:'自动交易',
      userCenter: '个人中心',
      digitalMarket: '缠论看盘',
      tradingPlanet: '缠论地图',
      more: '发现更多',
      change: '切换',
      train:'交易训练'
    },
    //策略中心页面
    strategy: {
      gridStrategy: "网格策略",
      trendStrategy: "趋势策略",
      arbitrageStrategy: "套利策略",
      hedgingStrategy: "对冲策略",
      meanReversionStrategy: "均值策略",
    },
    //缠论
    cl: {
      contract: '合约',
      initialCapital: '初始资金',
      trainingStartDate: '训练开始日期',
      startTrain: '开始训练',
      endTrain: '结束训练',
      customizedAssisted: '定制辅助交易',
      lineDay: '日线',
      lineHour: '小时',
      line30: '30分钟',
      line15: '15分钟',
      line5: '5分钟',
      lineMulti: '多周期共列',
      tip: '本缠论工具只做技术分析模拟训练使用，所有训练结果不够成投资建议，投资有风险，入市需谨慎！如需要实盘交易软件请联系客服对接正规券商开户。'
    },
    //训练记录
    history: {
      symbol: '代码',
      tradeSubject: '类型',
      startDate: '开始日期',
      endDate: '结束日期',
      initFund: '初始资金',
      endFund: '结束资金',
      profit: '收益',
      profitRate: '收益率',
      createTime: '训练时间',
      detail: '详情'
    },
    //回测
    back: {
      gridStrategy: "网格策略",
      trendStrategy: "趋势策略",
      arbitrageStrategy: "套利策略",
      hedgingStrategy: "对冲策略",
      meanReversionStrategy: "均值策略",
      //网格策略
      grid: {
        parameterSettings: '参数设定',
        loopOpen: '是否开启循环开仓',
        openBlateralPositions: '是否开启双边持仓',
        firstOrderDirection: '首单方向',
        long: '多',
        short: '空',
        longSet: '多头开仓设置',
        shortSet: '空头开仓设置',
        longOpen: '多头首单开仓手数',
        longRatio: '多头首单止盈参数',
        addWay: '价格加仓方式',
        shortOpen: '空头首单开仓手数',
        shortRatio: '空头首单止盈参数',
        addGrid: '新增网格',
        deleteGrid: '删除网格',
        grid: '网格',
        number: '数值',
        price: '价格',
        profit: '止盈',
        backtestAnalysis: '回测分析'
      },
      //网格策略回测结果
      backModal: {
        title: '网格策略回测分析',
        backtestingIndicators: '回测指标',
        backtestCurve: '回测曲线',
        param: '分析结果'
      }
    },
    //实盘
    trade: {
      contractSelection: '合约自选',
      marketUpdateTime: '行情更新时间',
      dayLine: '日线'
    },
    //缠论星球
    earth: {
      varietyNumer: '数量',
      firstBuy: '一买',
      secondBuy: '二买',
      thirdBuy: '三买',
      firstSell: '一卖',
      secondSell: '二卖',
      thirdSell: '三卖',
      plate: '板块'
    },
    //个人中心
    user: {
      logOut: '退出登录',
      logOutTip: '确定退出当前账户吗？'
    }
  },
};

const i18n = createI18n({
  // locale: 'en', // 默认语言
  locale: 'zh', // 默认语言
  messages,
});

export default i18n;