import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  { path: '/', name: 'homeLogin', component: () => import('@/views/login/login-index.vue') },
  { path: '/login', name: 'login', component: () => import('@/views/login/login-index.vue') },
  { path: '/train', name: 'train', redirect: '/stock/train' },
  { path: '/earth', name: 'earth', redirect: '/stock/earth' },
  { path: '/future', name: 'future', redirect: '/stock/future' },
  { path: '/history', name: 'future', redirect: '/stock/history' },
  { path: '/trade', name: 'future', redirect: '/stock/trade' },
  { path: '/warn', name: 'future', redirect: '/stock/warn' },
  { path: '/ai', name: 'future', redirect: '/stock/ai' },
  // {
  //   path: '/', name: 'hone', component: () => import('@/views/home-view.vue'),
  //   children: [
  //     { path: '/train', name: 'train', component: () => import('@/views/stock/stock-train-index.vue') },
  //     { path: '/earth', name: 'earth', component: () => import('@/views/earth/earth-index.vue') },
  //     { path: '/future', name: 'future', component: () => import('@/views/future/future-train-index.vue') },
  //     { path: '/history', name: 'history', component: () => import('@/views/history/history-index.vue') },
  //     { path: '/trade', name: 'trade', component: () => import('@/views/trade/trade-index.vue') },
  //     { path: '/warn', name: 'warn', component: () => import('@/views/warn/warn-index.vue') },
  //     { path: '/ai', name: 'ai', component: () => import('@/views/ai/ai-index.vue') },
  //   ]
  // },
  {
    path: '/stock', component: () => import('@/views/home-view.vue'),
    children: [
      { path: 'train', name: 'stock/train', component: () => import('@/views/stock/stock-train-index.vue') },
      { path: 'history', name: 'stock/history', component: () => import('@/views/stock/stock-history-index.vue') },
      { path: 'trade', name: 'stock/trade', meta: { ch: '看盘系统' }, component: () => import('@/views/stock/stock-trade-index.vue') },
      { path: 'earth', name: 'stock/earth', meta: { ch: '缠论地图' }, component: () => import('@/views/stock/stock-earth-index.vue') },
      { path: 'warn', name: 'stock/warn', meta: { ch: '自动交易' }, component: () => import('@/views/stock/stock-changlun-index.vue') },
      { path: 'ai', name: 'stock/ai', component: () => import('@/views/ai/ai-index.vue') },
      { path: 'user', name: 'stock/user', component: () => import('@/views/user/user-center-index.vue') },
    ]
  },
  {
    path: '/future', component: () => import('@/views/future-view.vue'),
    children: [
      { path: 'train', name: 'future/train', component: () => import('@/views/future/future-train-index.vue') },
      { path: 'history', name: 'future/history', component: () => import('@/views/future/future-history-index.vue') },
      { path: 'trade', name: 'future/trade', meta: { ch: '看盘系统' }, component: () => import('@/views/future/future-trade-index.vue') },
      { path: 'earth', name: 'future/earth', meta: { ch: '缠论地图' }, component: () => import('@/views/future/future-earth-index.vue') },
      { path: 'warn', name: 'future/warn', meta: { ch: '自动交易' }, component: () => import('@/views/future/future-changlun-index.vue') },
      { path: 'ai', name: 'future/ai', component: () => import('@/views/ai/ai-index.vue') },
      { path: 'user', name: 'future/user', component: () => import('@/views/user/user-center-index.vue') },
    ]
  },
  {
    path: '/coin', component: () => import('@/views/coin-view.vue'),
    children: [
      { path: 'square', name: 'coin/square', meta: { titleName: 'strategySquare' }, component: () => import('@/views/square/square-index.vue') },
      { path: 'back', name: 'coin/back',meta: { titleName: 'backTrade' }, component: () => import('@/views/backtrade/back-index.vue') },
      { path: 'train', name: 'coin/train',meta: { titleName: 'simulatedTrading' }, component: () => import('@/views/coin/coin-train-index.vue') },
      { path: 'review', name: 'coin/review',meta: { titleName: 'trainingReview' }, component: () => import('@/views/coin/coin-history-index.vue') },
      { path: 'warn', name: 'coin/warn', meta: { ch: 'autoTrade' }, component: () => import('@/views/coin/coin-changlun-index.vue') },
      { path: 'trade', name: 'coin/trade',meta: { titleName: 'digitalMarket' }, component: () => import('@/views/coin/coin-trade-index.vue') },
      { path: 'earth', name: 'coin/earth',meta: { titleName: 'tradingPlanet' }, component: () => import('@/views/coin/coin-earth-index.vue') },
      { path: 'real', name: 'coin/real',meta: { titleName: 'realStrategy' }, component: () => import('@/views/real/real-index.vue') },
      { path: 'tool', name: 'coin/tool',meta: { titleName: 'tradeTool' }, component: () => import('@/views/tool/tool-index.vue') },
      { path: 'user', name: 'coin/user',meta: { titleName: 'userCenter' }, component: () => import('@/views/user/user-center-index.vue') },
    ]
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
