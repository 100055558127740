<template>
  <a-config-provider :locale="model.appLocale" :theme="{
    token: {
      colorPrimary: '#448DF4',
      borderRadius: 0,
      colorBgContainer: '#141623',
      colorBgElevated: '#141623'
    }, algorithm: theme.darkAlgorithm,
  }">
    <router-view />
  </a-config-provider>
</template>

<script setup>
import { theme } from 'ant-design-vue';
import zhCN from 'ant-design-vue/es/locale/zh_CN';
import enUs from 'ant-design-vue/es/locale/en_US';
import { useI18n } from 'vue-i18n';
const { locale } = useI18n();
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
import { onBeforeMount, getCurrentInstance, reactive, watch } from 'vue';
const companyConfig = getCurrentInstance().appContext.config.globalProperties.companyConfig;
const model = reactive({
  appLocale: enUs
});
// 监听 locale 的变化
watch(locale, (newLocale) => {
  model.appLocale = newLocale === 'en' ? enUs : zhCN;
  dayjs.locale(newLocale === 'en' ? 'en' : 'zh-cn');
});
onBeforeMount(() => {
  document.title = `${companyConfig.productName}`
  document.getElementById('favicon').href = `${companyConfig.icon}`;
  model.appLocale = locale.value === 'en' ? enUs : zhCN;
  dayjs.locale('zh-cn');
});
</script>
<style>
#app {
  font-family: Arial, Avenir, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #cccccc;
  font-size: 14px;
}

.ant-drawer-body {
  color: #cccccc;
}

body {
  background-color: var(--bgBody);
  /* min-width: 1280px; */
}

@media (min-width: 800px) {
  body {
    min-width: 1280px;
  }
}

body,
p {
  margin: 0 !important;
  padding: 0 !important;
}

/* 分页 */
.pagination-bar {
  text-align: right;
  margin-top: 10px;
}

#webpack-dev-server-client-overlay {
  display: none !important;
}

::-webkit-scrollbar {
  width: 5px;
  /* 纵向滚动条*/
  height: 5px;
  /* 横向滚动条 */
  /* background-color: #fff; */
  background-color: #448df427;
}

/*定义滚动条轨道 内阴影*/
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  /* background-color: #fff; */
  background-color: #448df427;
}

/*定义滑块 内阴影*/
::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  background-color: rgba(164, 166, 172, 0.3);
  border-radius: 10px;
}
</style>
